





































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { getClassFromStatus } from '@movici-flow-common/utils';
import GenerateDialog from './GenerateDialog.vue';
import { ShortDatasetGenerator } from '@/types';
import { datasetGeneratorStore, generalStore, projectStore } from '@/store/store-accessor';
import ResourceListConsumer from '@/components/common/ResourceListConsumer';
import ResourceList from '@/components/common/ResourceList.vue';
import { MovActionType } from '@movici-flow-common/types';
import DialogModal from '@/components/global-alt/DialogModal.vue';
@Component({
  name: 'DatasetGenerator',
  components: {
    ResourceList
  }
})
export default class DatasetGenerator extends Mixins<ResourceListConsumer<ShortDatasetGenerator>>(
  ResourceListConsumer
) {
  @Prop({ type: Number, default: 3000 }) readonly pollingInterval!: number;
  polling: NodeJS.Timeout | null = null;
  editPage = 'DatasetGeneratorEdit';
  resourceTerm = 'dataset generator';
  defaultSort = 'name';
  filterKeys = ['name', 'status'];

  get activeProject() {
    return projectStore.activeProject;
  }

  async getAll() {
    return this.activeProject
      ? await datasetGeneratorStore.getDatasetGenerators(this.activeProject.uuid)
      : [];
  }

  getClassFromStatus = getClassFromStatus;

  getDeleteItemRequests(itemList: ShortDatasetGenerator[]) {
    return itemList.map(element => datasetGeneratorStore.deleteDatasetGenerator(element));
  }

  onShowLogs(item: ShortDatasetGenerator) {
    this.$router.push({
      name: 'DatasetGeneratorLogs',
      params: { uuid: item.uuid }
    });
  }

  onCancel(generator_uuid: string) {
    this.$oruga.modal.open({
      parent: this,
      component: DialogModal,
      props: {
        message: 'Are you sure you want to cancel?',
        variant: 'warning',
        hasIcon: true,

        onConfirm: async () => {
          await datasetGeneratorStore.cancelGeneration(generator_uuid);
          await this.resetList();
        }
      }
    });
  }

  async onGenerate(shortGenerator: ShortDatasetGenerator) {
    const generator = await datasetGeneratorStore.getDatasetGenerator(shortGenerator.uuid);
    if (generator) {
      this.$oruga.modal.open({
        parent: this,
        component: GenerateDialog,
        props: { generator_uuid: generator.uuid, dataset_generator: generator }
      });
    }
  }

  actionsRow(generator: ShortDatasetGenerator) {
    const actions = [];
    if (
      generator.last_workload_status === 'Running' ||
      generator.last_workload_status === 'Pending'
    ) {
      actions.push(MovActionType.CANCEL);
    } else {
      actions.push(MovActionType.GENERATE);
    }

    actions.push(MovActionType.LOGS, MovActionType.EDIT, MovActionType.DELETE);

    return actions;
  }

  checkedRowEnabledActionsRow(generator: ShortDatasetGenerator) {
    let actions = [];

    if (
      generator.last_workload_status === 'Running' ||
      generator.last_workload_status === 'Pending'
    ) {
      // Only running or pending can be cancelled
      actions.push(MovActionType.CANCEL);
    } else {
      actions.push(MovActionType.GENERATE);
    }

    if (generator.last_workload_status !== null && generator.last_workload_status !== undefined) {
      // If no last workload status, there is no workload and thus no activity logs
      actions.push(MovActionType.LOGS);
    }

    actions.push(MovActionType.EDIT, MovActionType.DELETE);

    return actions;
  }

  afterMounted() {
    this.polling = setInterval(() => {
      this.resetList();
    }, this.pollingInterval);
  }

  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  }

  @Watch('activeProject')
  afterActiveProject() {
    if (this.activeProject) {
      this.resetList();
    }
  }

  setLoading(payload: { value: boolean; msg?: string }) {
    generalStore.setLoading(payload);
  }
}
