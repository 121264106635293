














































































import { Component, Vue, Prop } from 'vue-property-decorator';
import orderBy from 'lodash/orderBy';
import { DatasetGenerator, DatasetGeneratorDataset, DGDatasetType } from '@/types';
import { Dataset } from '@movici-flow-common/types';
import { datasetGeneratorStore, datasetStore } from '@/store/store-accessor';

@Component({ name: 'GenerateDialog' })
export default class GenerateDialog extends Vue {
  @Prop({ type: String, required: true }) readonly generator_uuid!: string;
  @Prop({ type: Object, default: null }) readonly dataset_generator!: DatasetGenerator | null;
  datasets_checked: string[] = [];
  datasets: Dataset[] = [];
  flags = [];

  get dataset_types(): DGDatasetType[] {
    return datasetGeneratorStore.dataset_types;
  }

  get orderedNonExistingDatasets(): DatasetGeneratorDataset[] {
    return orderBy(this.dataset_generator?.datasets, 'name').filter(dataset => {
      return !this.datasetExists(dataset.name);
    });
  }

  get orderedExistingDatasets(): DatasetGeneratorDataset[] {
    return orderBy(this.dataset_generator?.datasets, 'name').filter(dataset => {
      return this.datasetExists(dataset.name);
    });
  }

  async onGenerate() {
    const message = await datasetGeneratorStore.generate({
      generator_uuid: this.generator_uuid,
      payload: { datasets: this.datasets_checked, flags: this.flags }
    });

    this.$flow.snackbar.successMessage(message);
    this.$emit('close');
  }

  getDatasetTypeDisplayName(dataset_type: string) {
    let matching_dataset_types = this.dataset_types.filter(dt => {
      return dt.type === dataset_type;
    });

    if (matching_dataset_types.length > 0) {
      return matching_dataset_types[0].display_name;
    }

    return '';
  }

  async getDatasets() {
    this.datasets = await datasetStore.getDatasets();
  }

  datasetExists(dataset_name: string) {
    return (
      this.datasets.filter(existing_dataset => {
        return existing_dataset.name === dataset_name && existing_dataset.has_data;
      }).length > 0
    );
  }

  onSelectAll(datasets: DatasetGeneratorDataset[]) {
    datasets.forEach(dataset => {
      if (!this.datasets_checked.includes(dataset.type)) {
        this.datasets_checked.push(dataset.type);
      }
    });
  }

  onDeselectAll(datasets: DatasetGeneratorDataset[]) {
    for (let i = 0; i < datasets.length; ++i) {
      for (let j = 0; j < this.datasets_checked.length; ++j) {
        if (datasets[i].type === this.datasets_checked[j]) {
          this.datasets_checked.splice(j, 1);
        }
      }
    }
  }

  async mounted() {
    await Promise.all([
      this.getDatasets(),
      datasetGeneratorStore.getDatasetGenerator(this.generator_uuid),
      datasetGeneratorStore.getDGSDatasetTypes()
    ]);

    for (let dataset of this.dataset_generator?.datasets ?? []) {
      // Check if dataset already exists (then do not generate it by default)
      if (!this.datasetExists(dataset.name)) {
        this.datasets_checked.push(dataset.type);
      }
    }
  }
}
